<template>
  <section id="banner-placeholder" class="banner-placeholder">
    <div v-if="iconInline" class="title-header">
      <div class="heading">
        <Icon v-if="icon" :name="icon" width="48px" height="48px" class="icon-inline" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 class="title" v-html="DOMPurify.sanitize(title)" />
      </div>
      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
      <ReadMoreDescription
        v-else-if="description"
        :content="description"
        :number-of-lines="3"
        class="description"
        @click="emitScroll()"
      />
    </div>
    <div v-else class="title-header">
      <Icon v-if="icon" :name="icon" width="48px" height="48px" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h2 class="title" v-html="DOMPurify.sanitize(title)" />
      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
      <ReadMoreDescription
        v-else-if="description"
        :content="description"
        :number-of-lines="3"
        class="description"
        @click="emitScroll()"
      />
    </div>
    <slot />
  </section>
</template>

<script lang="ts" setup>
import DOMPurify from '@/lib/DOMPurify';

defineProps<{
  title: string;
  subtitle?: string;
  description?: string;
  icon?: string;
  iconInline?: boolean;
}>();

const emit = defineEmits<{
  (event: 'handleVerify'): void;
}>();

function emitScroll(): void {
  emit('handleVerify');
}
</script>

<style scoped lang="scss">
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/typography.scss';
@use '$/misc.scss';

.banner-placeholder {
  @include misc.highlight();

  flex-direction: column;
  width: 100%;

  .title-header {
    flex-direction: column;
    align-items: center;
    padding: 0 spacings.$desktop-horizontal-lg 0;
    text-align: center;

    .heading {
      align-items: center;
    }

    .icon-inline {
      margin-right: 20px;
    }

    .title {
      flex-wrap: wrap;

      font-size: typography.$desktop-h2;
      font-weight: 600;
      line-height: normal;
      white-space: pre;
    }

    .subtitle {
      margin-top: 24px;
      font-size: typography.$desktop-body-l;
    }

    .description {
      margin-top: 22px;
      font-size: 16px;
      line-height: 18px;
      text-align: left;
    }
  }
}

@include breakpoints.mobile() {
  .banner-placeholder {
    .title-header {
      padding: spacings.$mobile-section 0 0;

      .title {
        display: inline-block;

        font-size: typography.$mobile-h2;
        line-height: normal;
        text-align: left;
        white-space: break-spaces;
      }

      .heading {
        align-self: center;
        padding: 0 0 0 spacings.$mobile-section;
      }

      .icon-inline {
        width: 40px;
        height: 40px;
      }

      .subtitle {
        margin-top: 16px;
        padding: 0 spacings.$mobile-horizontal;
        font-size: typography.$mobile-body-l;
        text-align: left;
      }

      .description {
        padding: 0 spacings.$mobile-horizontal;
      }
    }
  }
}
</style>
